const firebaseBaseUrl = 'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/';

export const rideImages: {
    [park: string]: {
        [theme: string]: {
            [ride: string]: string;
        };
    };
} = {
    cawh: {
        default: {
            Blast: '/imgs/cawh/default/blast1.jpg',
            Condor: '/imgs/cawh/default/condor1.jpg',
            'Crazy River': '/imgs/cawh/default/crazyriver1.jpg',
            'Eat My Dust': '/imgs/cawh/default/eatmydust2.jpg',
            'El Rio Grande': '/imgs/cawh/default/elriogrande1.jpg',
            Goliath: '/imgs/cawh/default/goliath1.jpg',
            'Lost Gravity': '/imgs/cawh/default/lostgravity1.jpg',
            'Space Shot': '/imgs/cawh/default/spaceshot2.jpg',
            'Speed of Sound': '/imgs/cawh/default/speedofsound2.jpg',
            UNTAMED: '/imgs/cawh/default/untamed1.jpg',
            'Xpress: Platform 13': '/imgs/cawh/default/xpress1.jpg',
            "Merlin's Magic Castle": '/imgs/cawh/default/merlin1.jpg'
        },
        hfn: {
            Blast: `${firebaseBaseUrl}hfn-ride-images%2Ftiles%2Fblast2.png?alt=media`,
            Condor: `${firebaseBaseUrl}hfn-ride-images%2Ftiles%2Fcondor.png?alt=media`,
            'Crazy River': `${firebaseBaseUrl}hfn-ride-images%2Ftiles%2Fcrazyriver.png?alt=media`,
            'Eat My Dust': `${firebaseBaseUrl}hfn-ride-images%2Ftiles%2Featmydust.png?alt=media`,
            'El Rio Grande': `${firebaseBaseUrl}hfn-ride-images%2Ftiles%2Felriogrande.png?alt=media`,
            Goliath: `${firebaseBaseUrl}hfn-ride-images%2Ftiles%2Fgoliath.png?alt=media`,
            'Lost Gravity': `${firebaseBaseUrl}hfn-ride-images%2Ftiles%2Flostgravity.png?alt=media`,
            'Space Shot': `${firebaseBaseUrl}hfn-ride-images%2Ftiles%2Fspaceshot.png?alt=media`,
            'Speed of Sound': `${firebaseBaseUrl}hfn-ride-images%2Ftiles%2Fspeedofsound.png?alt=media`,
            UNTAMED: `${firebaseBaseUrl}hfn-ride-images%2Ftiles%2Funtamed.png?alt=media`,
            Untamed: `${firebaseBaseUrl}hfn-ride-images%2Ftiles%2Funtamed.png?alt=media`,
            'Xpress: Platform 13': `${firebaseBaseUrl}hfn-ride-images%2Ftiles%2Fxpress.png?alt=media`,
            "Merlin's Magic Castle": `${firebaseBaseUrl}hfn-ride-images%2Ftiles%2Fmerlin1.png?alt=media`,
            "Merlin's Magic Christmas Castle": `${firebaseBaseUrl}hfn-ride-images%2Ftiles%2Fmerlin1.png?alt=media`
        },
        'hfn-thumbs': {
            Blast: `${firebaseBaseUrl}hfn-ride-images%2Fthumbs%2Fblast.png?alt=media`,
            Condor: `${firebaseBaseUrl}hfn-ride-images%2Fthumbs%2Fcondor.png?alt=media`,
            'Crazy River': `${firebaseBaseUrl}hfn-ride-images%2Fthumbs%2Fcrazyriver.png?alt=media`,
            'Eat My Dust': `${firebaseBaseUrl}hfn-ride-images%2Fthumbs%2Featmydust.png?alt=media`,
            'El Rio Grande': `${firebaseBaseUrl}hfn-ride-images%2Fthumbs%2Felriogrande.png?alt=media`,
            Goliath: `${firebaseBaseUrl}hfn-ride-images%2Fthumbs%2Fgoliath.png?alt=media`,
            'Lost Gravity': `${firebaseBaseUrl}hfn-ride-images%2Fthumbs%2Flostgravity.png?alt=media`,
            'Space Shot': `${firebaseBaseUrl}hfn-ride-images%2Fthumbs%2Fspaceshot.png?alt=media`,
            'Speed of Sound': `${firebaseBaseUrl}hfn-ride-images%2Fthumbs%2Fspeedofsound.png?alt=media`,
            UNTAMED: `${firebaseBaseUrl}hfn-ride-images%2Fthumbs%2Funtamed.png?alt=media`,
            Untamed: `${firebaseBaseUrl}hfn-ride-images%2Fthumbs%2Funtamed.png?alt=media`,
            'Xpress: Platform 13': `${firebaseBaseUrl}hfn-ride-images%2Fthumbs%2Fxpress.png?alt=media`,
            "Merlin's Magic Castle": `${firebaseBaseUrl}hfn-ride-images%2Fthumbs%2Fmerlin.png?alt=media`
        }
    }
};
